<template>
  <div class="main main--internal">
    <div class="page-header__container">
      <h1 class="page-header">{{ navigator.title }}</h1>
    </div>
    <div class="articles__container">
      <nav class="articles">
        <router-link :to="{ name: 'article', params: { id: article.id } }" :key="article.id" class="articles__item" v-for="article in articles">
          <img :alt="article.title" :src="$store.state.api + article.image" class="articles__item__image">
          <div class="articles__item__text">
            <h2 class="articles__item__title">{{ article.title }}</h2>
            <p :title="article.description" class="articles__item__subtitle">{{ article.description.substr(0, 120) }}{{ article.description.length > 120 ? '...' : '' }}</p>
          </div>
        </router-link>
      </nav>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ArticlesList',
  async asyncData ({ store }) {
    await store.dispatch('GET_ARTICLES')
  },
  computed: {
    articles () {
      return this.$store.state.articles_page ? this.$store.state.articles_page.articles : []
    },
    navigator () {
      return this.$store.state.articles_page ? this.$store.state.articles_page.navigator : []
    }
  },
  metaInfo () {
    const navigator = this.$store.state.articles_page.navigator
    return this.$seo(
      'common',
      navigator.title,
      navigator.meta_keywords,
      navigator.description,
      navigator.title,
      null,
      navigator.description
    )
  }
}
</script>
